<template>
  <footer id="main-footer" class="footer-mobile bg-cl-white-smoke pt15">
    <div v-show="isTablet && isComparePage" class="col-xs-12 middle-xs save-bottom center-xs flex cl-dark-blue pointer" @click="toogleCompareFooter = !toogleCompareFooter, toogleFooter = !toogleFooter">
      <p class="m0 py20 pr10" v-if="!toogleCompareFooter">
        {{ $t('Pokaż stopkę') }}
      </p>
      <p class="m0 py20 pr10" v-else>
        {{ $t('Ukryj stopkę') }}
      </p>
      <i v-if="!toogleCompareFooter" class="arrow down open-footer-icon mb3" />
      <i v-else class="arrow up open-footer-icon mt5" />
    </div>
    <div v-if="isHomePage" class="container-fluid" :class="{'p0 mb20': isMobile}">
      <div class="container row margin-auto center-xs middle-xs" :class="{'pt10': isComparePage}" v-show="toogleFooter || !isTablet">
        <div id="styles-from-magento" class="w-100 seo-tooltip-footer m0 align-left col-md-11 fs-medium-small pt20" v-html="getCmsData" />
      </div>
    </div>
    <div v-show="toogleCompareFooter || !isComparePage" class="container-fluid no-padd-mobile" :class="{'bg-cl-white': !isHomePage}">
      <div class="row container margin-auto info-footer">
        <div class="col-xs-12 col-md-4 flex info-box middle-xs" :class="isHomePage ? 'border-info-box-homepage' : 'border-info-box'">
          <template v-if="!isHomePage">
            <img
              v-lazy="'/assets/svg/ZWROT.svg'"
              class="footer-img"
              alt="zwrot"
              width="100"
              height="85"
            >
            <div class="pl10 cl-alternative">
              <p class="h4 my5 weight-500">
                14 DNI NA ZWROT
              </p>
              <p>Jeżeli zakupy nie spełniają Twoich oczekiwań, możesz je nam zwrócić w ciągu 14 dni kalendarzowych</p>
            </div>
          </template>
          <template v-else>
            <img
              v-lazy="'/assets/svg/Location.svg'"
              width="100"
              height="134"
              alt="Location"
            >
            <div class="pl10 cl-alternative">
              <p class="h4 my5 weight-500">
                NASZ SALON w Warszawie
              </p>
              <router-link :to="localizedRoute('/i/wirtualny-spacer')" exact class="cl-dark-blue fs-medium-small">
                Więcej informacji >
              </router-link>
            </div>
          </template>
        </div>
        <div class="col-xs-12 col-md-4 flex info-box middle-xs" :class="isHomePage ? 'middle-info-box-homepage' : 'middle-info-box'">
          <template v-if="!isHomePage">
            <img
              v-lazy="'/assets/svg/B_płatności.svg'"
              class="footer-img"
              alt="płatności"
              width="100"
              height="133"
            >
            <div class="pl10 cl-alternative">
              <p class="h4 my5 weight-500">
                BEZPIECZNE PŁATNOŚCI
              </p>
              <p>
                Wybierz dogodny sposób płatności:
                gotówką, pobranie, przelew szybki i tradycyjny, karta płatnicza
              </p>
            </div>
          </template>
          <template v-else>
            <img v-lazy="'/assets/svg/Wycena.svg'" class="footer-img-fixed" alt="Wycena">
            <div class="pl10 cl-alternative">
              <p class="h4 my5 weight-500">
                WYCENA
              </p>
              <router-link :to="localizedRoute('/i/wycena-lazienki')" exact class="cl-dark-blue fs-medium-small">
                Więcej informacji >
              </router-link>
            </div>
          </template>
        </div>
        <div class="col-xs-12 col-md-4 flex info-box middle-xs" :class="isHomePage ? 'border-info-box-homepage' : 'border-info-box'">
          <template v-if="!isHomePage">
            <img
              v-lazy="'/assets/svg/B_zakupy.svg'"
              class="footer-img"
              alt="zakupy"
              width="100"
              height="132"
            >
            <div class="pl10 cl-alternative">
              <p class="h4 my5 weight-500">
                BEZPIECZNE ZAKUPY
              </p>
              <p>
                Korzystamy z sertyfikatu SSL, który gwarantuje najwyższe bezpieczeństwo Twoich transakcji
              </p>
            </div>
          </template>
          <template v-else>
            <img v-lazy="'/assets/svg/TaxFree.svg'" class="footer-img-fixed" alt="TaxFree">
            <div class="pl10 cl-alternative">
              <p class="h4 my5 weight-500">
                TaxFree
              </p>
              <router-link :to="localizedRoute('/i/tax-free')" exact class="cl-dark-blue fs-medium-small">
                Więcej informacji >
              </router-link>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div v-show="toogleCompareFooter || !isComparePage" class="container-fluid bg-cl-yellow" :class="{'p0': isMobile}">
      <div class="container row margin-auto">
        <div class="col-xs-12 py25 px5">
          <p class="m0 center-xs cl-alternative weight-500 size-bar">
            NAJWIĘKSZY WYBÓR GRZEJNIKÓW DEKORACYJNYCH W POLSCE
          </p>
        </div>
      </div>
    </div>
    <div v-show="isTablet && !isComparePage" class="col-xs-12 middle-xs center-xs flex cl-dark-blue pointer save-bottom" @click="toogleFooter = !toogleFooter">
      <p class="m0 py20 pr10" v-if="!toogleFooter">
        {{ $t('Pokaż stopkę') }}
      </p>
      <p class="m0 py20 pr10" v-else>
        {{ $t('Ukryj stopkę') }}
      </p>
      <i v-if="!toogleFooter" class="arrow down open-footer-icon mb3" />
      <i v-else class="arrow up open-footer-icon mt5" />
    </div>
    <div class="container-fluid" :class="{'p0 mb20': isMobile}">
      <div class="container row margin-auto center-xs middle-xs obserwuj" :class="{'pt10': isComparePage}" v-show="toogleFooter || !isTablet">
        <p class="uppercase size-bar col-xs-12 col-md-4 cl-dark-blue weight-500 m0" :class="{'pb20': isMobile }">
          Obserwuj nas
        </p>
        <div class="col-xs-12 col-md-4">
          <a
            class="social-icon mx10 brdr-circle no-underline"
            href="https://pl-pl.facebook.com/AG.Grzejniki.Design/"
            :aria-label="$t('Go to Facebook')"
            target="_blank"
            rel="noreferrer"
          >
            <i class="icon-fb icon-size cl-dark-blue" />
          </a>
          <a
            class="social-icon mx10 brdr-circle no-underline"
            href="https://www.instagram.com/ag_grzejniki_design/"
            :aria-label="$t('Go to Instagram')"
            target="_blank"
            rel="noreferrer"
          >
            <i class="icon-Insta icon-size cl-dark-blue" />
          </a>
          <a
            class="social-icon mx10 brdr-circle no-underline"
            href="https://www.youtube.com/channel/UCz64TTccXJ61aO_rnFNvw7g"
            :aria-label="$t('Go to Youtube')"
            target="_blank"
            rel="noreferrer"
          >
            <i class="icon-youtube icon-size cl-dark-blue" />
          </a>
          <a
            class="social-icon mx10 brdr-circle no-underline"
            href="https://twitter.com/AgGrzejniki?lang=en"
            :aria-label="$t('Go to Twitter')"
            target="_blank"
            rel="noreferrer"
          >
            <i class="icon-twitter icon-size cl-dark-blue" />
          </a>
          <a
            class="social-icon mx10 brdr-circle no-underline"
            href="https://pl.pinterest.com/aggrzejnikidesign/"
            :aria-label="$t('Go to Pinterest')"
            target="_blank"
            rel="noreferrer"
          >
            <i class="icon-pinterest icon-size cl-dark-blue" />
          </a>
        </div>
      </div>
    </div>
    <div class="container-fluid bg-cl-dark-blue no-padd-mobile">
      <div v-show="toogleFooter || !isTablet" class="container row margin-auto py40" :class="{'py60': !isMobile }">
        <div v-show="isTablet" class="col-xs-12 align-center">
          <i class="icon-ag_grzejniki-logo icon-size cl-white" />
        </div>
        <div class="col-xs-12 col-md-4 center-xs">
          <p class="uppercase m0 mt40 mb20 cl-white">
            Przydatne linki
          </p>
          <div class="pb5" v-for="(link, i) in links" :key="i + 'linki'">
            <router-link class="cl-white no-underline fs-medium-small weight-100" :to="localizedRoute(link.link)" exact>
              {{ $t(link.title) }}
            </router-link>
          </div>
        </div>
        <div class="col-xs-12 col-md-4 center-xs">
          <p class="uppercase m0 mt40 mb20 cl-white">
            Informacje
          </p>
          <div class="pb5" v-for="(link, i) in informations" :key="i + 'info'">
            <router-link class="cl-white no-underline fs-medium-small weight-100" :to="localizedRoute(link.link)" exact>
              {{ $t(link.title) }}
            </router-link>
          </div>
        </div>
        <div class="col-xs-12 col-md-4 center-xs">
          <p class="uppercase m0 mt40 mb20 cl-white">
            Kontakt
          </p>
          <div class="pb5" v-for="(link, i) in contacts" :key="i + 'kontakt'">
            <router-link class="cl-white no-underline fs-medium-small weight-100" :to="localizedRoute(link.link)" exact>
              {{ $t(link.title) }}
            </router-link>
          </div>
          <span @click="$bus.$emit('modal-show', 'contact-modal')" class="pointer cl-white fs-medium-small weight-100">{{ $t('Zadzwoń do nas!') }}</span>
        </div>
      </div>
    </div>
    <div v-show="toogleFooter || !isTablet" class="row center-xs bg-cl-alternative align-center py35 flex">
      <div class="other-links col-xs-12 col-md-2" v-for="(link, i) in other" :key="i + 'other'">
        <router-link class="cl-white no-underline fs-medium-small weight-100" :to="localizedRoute(link.link)" exact>
          {{ $t(link.title) }}
        </router-link>
      </div>
      <div class="col-xs-12 col-md-6 end-md save-bottom">
        <img class="bg-cl-white px7 icon-pay" v-lazy="'/assets/Google_Pay_Logo.svg'" alt="Google play logo" width="auto" height="25px">
        <img class="bg-cl-white px7 icon-pay" v-lazy="'/assets/visa_logo.svg'" alt="Visa logo" width="auto" height="25px">
        <img class="bg-cl-white px7 icon-pay" v-lazy="'/assets/mc_symbol.svg'" alt="MasterCard logo" width="auto" height="25px">
        <img class="bg-cl-white px7 icon-pay" v-lazy="'/assets/PayU_logo.svg'" alt="payU logo" width="auto" height="25px">
        <img class="bg-cl-white px7 icon-pay" v-lazy="'/assets/PayPal_logo.svg'" alt="PayPal logo" width="auto" height="25px">
        <img class v-lazy="'/assets/certum.png'" alt="Certum logo" width="92" height="25">
      </div>
    </div>
    <contact-modal />
    <back-to-top bottom="97px" right="15px" visibleoffset="200">
      <button type="button" class="btn-top button no-outline brdr-none cl-white bg-cl-mine-shaft :bg-cl-th-secondary py10 px10">
        <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd">
          <path d="M23.245 20l-11.245-14.374-11.219 14.374-.781-.619 12-15.381 12 15.391-.755.609z" fill="white" />
        </svg>
      </button>
    </back-to-top>
  </footer>
</template>

<script>
import { mapGetters } from 'vuex'
import CurrentPage from 'theme/mixins/currentPage'
import BackToTop from 'theme/components/core/BackToTop'
import i18n from '@vue-storefront/i18n'
import { MobileDetected } from '../../mobileDetected.ts'
import { isServer } from '@vue-storefront/core/helpers'

const ContactModal = () => import(/* webpackChunkName: "vsf-contact-modal" */ 'theme/components/core/blocks/Product/ContactModal.vue')

export default {
  mixins: [CurrentPage, MobileDetected],
  name: 'MainFooter',
  data () {
    return {
      toogleCompareFooter: false,
      toogleFooter: false,
      links: [
        { title: i18n.t('Koszty dostawy'), link: i18n.t('/i/koszty-dostawy') },
        { title: i18n.t('Formy płatności'), link: i18n.t('/i/formy-platnosci') },
        // { title: i18n.t('Śledzenie zamówienia'), link: i18n.t('/i/sledzenie zamowienia') },
        { title: i18n.t('Zwroty i reklamacje'), link: i18n.t('/i/zwroty-i-reklamacje') }
      ],
      informations: [
        { title: i18n.t('Salony'), link: i18n.t('/i/salony') },
        { title: i18n.t('Nasz asortyment'), link: i18n.t('/i/nasz-asortyment') },
        { title: i18n.t('Dla Architektów'), link: i18n.t('/i/dla-architektow') },
        { title: i18n.t('Wirtualny spacer'), link: i18n.t('/i/wirtualny-spacer') },
        { title: i18n.t('Interaktywne Katalogi'), link: i18n.t('/i/interaktywne-katalogi') }
      ],
      contacts: [
        { title: i18n.t('Dane konaktowe'), link: i18n.t('/i/kontakt') }
        // { title: i18n.t('Formularz kontaktowy'), link: i18n.t('/i/kontakt') },
      ],
      other: [
        { title: i18n.t('Polityka prywatności'), link: i18n.t('/i/polityka-prywatnosci') },
        { title: i18n.t('Regulamin serwisu'), link: i18n.t('/i/regulamin-serwisu') }
      ]
    }
  },
  serverPrefetch () {
    return this.fetchCmsBlock()
  },
  created () {
    if (!isServer) {
      this.fetchCmsBlock()
    }
  },
  methods: {
    fetchCmsBlock () {
      // let queryKey = 'identifier'
      // let queryValue = 'homepage-seo'
      // if (queryKey && queryValue) {
      //   return this.$store.dispatch('cmsBlock/single', {
      //     key: queryKey,
      //     value: queryValue
      //   })
      // }
    },
    goToAccount () {
      this.$bus.$emit('modal-toggle', 'modal-signup')
    }
  },
  computed: {
    getCmsData () {
      let seo = this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`]('homepage-seo')
      return seo && seo.content ? seo.content : 'Obecnie najpopularniejsze i najbardziej praktyczne zarazem są grzejniki dekoracyjne, dostępne w wielu przeróżnych modelach i wersjach kolorystycznych.'
    },
    ...mapGetters({
      isLogged: 'user/isLoggedIn'
    }),
    getVersionInfo () {
      return `v${process.env.__APPVERSION__} ${process.env.__BUILDTIME__}`
    }
  },
  components: {
    ContactModal,
    BackToTop
  }
}
</script>

<style lang="scss">
.seo-tooltip-footer {
  h1 {
    font-size: 26px;
    margin: 0;
  }
}
</style>

<style lang="scss" scoped>
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
$color-secondary: color(secondary);
$light-border: color(gainsboro);

.footer-img-fixed {
  width: 100px;
  height: 100px;
  @media (max-width: 991px) {
    width: 70px;
    height: 70px;
  }
}
.footer-img {
  width: 100px;
  height: auto;
  @media (max-width: 991px) {
    width: 70px;
    height: auto;
  }
}
.icon-size {
  font-size: 40px;
}
.arrow {
  border: solid #3859A6;
  border-width: 0 1.5px 1.5px 0;
  display: inline-block;
  padding: 4px;
  right: 20px;
  bottom: 22px;
}
.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
.obserwuj {
  @media (min-width: 767px) {
    padding: 30px 0;
  }
}
.icon-pay {
  width: 55px;
  border-radius: 6px;
}
.no-padd-mobile {
  @media (max-width: 767px) {
    padding: 0
  }
}
.mobile-container {
  @media (max-width: 767px) {
    display: flex;
    justify-content: space-between;
    img {
      max-width: 20%;
      object-fit: contain;
    }
  }
}
.info-footer {
  padding-top: 50px;
  padding-bottom: 50px;
  @media (max-width: 767px) {
    padding: 0;
  }
}
.size-bar {
  font-size: 28px;
  @media (max-width: 767px) {
    font-size: 16px;
  }
}
.border-desktop {
  border-left: 3px solid $light-border;
  border-right: 3px solid $light-border;
}
.middle-info-box {
  border-left: 3px solid $light-border;
  border-right: 3px solid $light-border;
  @media (max-width: 767px) {
    border-left: none;
    border-right: none;
    border-bottom: 1px solid $light-border;
  }
}
.middle-info-box-homepage {
  border-left: 3px solid #FFF;
  border-right: 3px solid #FFF;
  @media (max-width: 767px) {
    border-left: none;
    border-right: none;
    border-bottom: 2px solid #FFF;
  }
}
.border-info-box {
  @media (max-width: 767px) {
    border-bottom: 1px solid $light-border;
  }
}
.border-info-box-homepage {
  @media (max-width: 767px) {
    border-bottom: 2px solid #FFF;
  }
}
.other-links {
  @media (max-width: 767px) {
    margin: 0 0 15px 0;
  }
}
.margin-auto {
  margin: 0 auto
}
.info-box {
  padding: 25px 20px;
  @media (max-width: 991px) {
    padding: 15px 10px;
  }
}
.footer-mobile {
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px), (max-width: 767px) {
    margin-bottom: 61px;
  }
}
.icon {
  transition: 0.3s all;
}
.social-icon {
  width: 40px;
  height: 40px;
  &:hover,
  &:focus,
  &:active {
    .icon {
      fill: $color-secondary;
    }
  }
}

@media (max-width: 595px) {
  .social {
    margin-top: 0;
  }
}
</style>
